import './recipe-hero-teaser.scss';

class RecipeHeroTeaser {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-recipeheroteaser'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$recipeHeroTeaser = $element;
        this.$recipeHeroTeaserImage = this.$recipeHeroTeaser.querySelector(`[${this.settings.initAttr}="image"]`);

        this.initialize();
    }

    initialize () {
        if (this.$recipeHeroTeaserImage) {
            this.setEvents();
        }
    }

    addBite () {
        if (!this.$recipeHeroTeaserImage.classList.contains('has--rotation')) {
            this.$recipeHeroTeaserImage.classList.add('has--rotation');

            setTimeout(() => {
                this.$recipeHeroTeaserImage.classList.add('has--one-bite');
            }, 800);
        }
    }

    setEvents () {
        this.$recipeHeroTeaserImage.addEventListener('mouseover', () => {
            if (this.$recipeHeroTeaserImage.getBoundingClientRect().right < window.innerWidth && window.innerWidth >= 1024) {
                this.addBite();
            }
        });
    }
}

export { RecipeHeroTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$recipeHeroTeaser = $context.querySelectorAll('[data-recipeheroteaser="root"');
        for (let i = 0; i < $$recipeHeroTeaser.length; i++) {
            const $recipeHeroTeaser = $$recipeHeroTeaser[i];

            const recipeHeroTeaserAPI = new RecipeHeroTeaser($recipeHeroTeaser);
            $recipeHeroTeaser.API = recipeHeroTeaserAPI;
        }
    }
});
